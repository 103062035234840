import {
  createReducer, on,
} from '@ngrx/store';
import { AuthUser } from 'src/app/shared/models/auth-user.model';
import { AuthActions } from '../action-types';

export const authFeatureKey = 'auth';

export interface AuthState {
  user?: AuthUser
}

export const initialState: AuthState = {
  user: undefined
};

export const auuthReducer = createReducer(
  initialState,

  on(AuthActions.login, (state, action) => {
    return {
      user: action.user
    }
  }),

  on(AuthActions.logout, (state, action) => initialState),

);
