import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs";
import { AuthActions } from "./action-types";
import { SharedDispatcher } from "../shared/state/shared.dispatcher.service";

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
      this.action$.pipe(
        ofType(AuthActions.login),
        tap(action => {
          localStorage.setItem('user', JSON.stringify(action.user));
          this.sharedDispatcher.loadNotifications();
          this.sharedDispatcher.loadCompanySettings();
        })
      ),
      {dispatch: false});

  logout$ = createEffect(() =>
  this.action$.pipe(
    ofType(AuthActions.logout),
    tap(action => {
      localStorage.removeItem('user');
      this.sharedDispatcher.resetWorkTrayReference();
      this.sharedDispatcher.clearCompanySettings();
      this.router.navigateByUrl('/login');
    })
  )
  ,{dispatch: false});

  constructor(
    private router: Router,
    private action$: Actions,
    private sharedDispatcher: SharedDispatcher
    ) {}


}
